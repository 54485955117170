

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'



Vue.config.productionTip = false

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll);

import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'


import interceptor from "./api/interceptor";
  interceptor();



window.toastr = require('toastr')
Vue.use(VueToastr2);


import AuthApi from "./api/auth";
Vue.prototype.$Auth = AuthApi;

import ModeratorApi from "./api/moderator";
Vue.prototype.$Moderator = ModeratorApi;

import MessageApi from "./api/message";
Vue.prototype.$MessageApi = MessageApi;

import DashbaordApi from "./api/dashboard";
Vue.prototype.$DashboardApi = DashbaordApi;

import moment from 'moment';
  Vue.mixin({
    methods: {
        formatDate(value,format){
          let setFormat = format?format:"MMM Do YYYY"
            if (!value) return ''
        value = moment(value).format(setFormat);
        return value;
    
          },
          hasRole(parentRole, subRole){
            let found = store.getters.navigationDetails.find(x=>x.parentRoleName ==parentRole);
            if(found){
              let subRoles = found.subRoles
            let index = subRoles.indexOf(subRole);
            return index!=-1?true:false;
            }
            return false;
          },
          hasAccess(parentRole){
            let found = store.getters.navigationDetails.find(x=>x.parentRoleName ==parentRole);
            if(found){
              return true;
            }else{
              return false;
            }
          }
    }
  })

  let test = 'localhost:3000/qna'


// working code

import VueSocketIO from 'vue-socket.io';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';

// let socketUrl = "http://localhost:3000/qna";
let socketUrl = process.env.VUE_APP_SOCKET_URL;

const socket = io(socketUrl, {
  autoConnect: false,
  extraHeaders: {
    Authorization: ""
  } 
});

Vue.use(VueSocketIOExt, socket);



new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
