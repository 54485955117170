import axios from 'axios';
import router from "./../router"

export default function setup() {
    axios.interceptors.request.use(function(config) {
        const token = JSON.parse(localStorage.getItem('loggedInUser'));
        if(token) {
            config.headers.Authorization = `Bearer ${token.accessCode.accessToken}`;
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(response => {
        return response;
     }, error => {
       if (error.response.status === 401) {
        //place your reentry code
        router.push({path:'/auth/sign-in'})
        
       }
       return Promise.reject(error);
     });
}