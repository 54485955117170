

const state = {
    loggedInUser:localStorage.getItem("loggedInUser")?JSON.parse(localStorage.getItem("loggedInUser")):null,
    loggedInUserDetails : localStorage.getItem("loggedInUserDetails")?JSON.parse(localStorage.getItem("loggedInUser")):null,
    navigationDetails: localStorage.getItem("navigationDetails"),
}

const mutations = {
    setLoggedInUser(state, payload) {
        state.loggedInUser = payload;
        localStorage.setItem("loggedInUser", JSON.stringify(payload));
    },
    setLoggedInUserDetails(state,payload){
        state.loggedInUserDetails = payload;
        localStorage.setItem("loggedInUserDetails", JSON.stringify(payload));
    },
    setNavigationDetails(state,payload){
        state.navigationDetails = payload;
        localStorage.setItem("navigationDetails", JSON.stringify(payload));
    },
   
    doLogout(state) {
        console.log("LOGGING OUT");
        localStorage.clear();
        state.loggedInUser = null;
        state.loggedInUserDetails = null;
    },
  
}

const actions = {

}

const getters = {
    loggedInUser: (state) => state.loggedInUser,
    loggedInUserDetails: (state) => state.loggedInUserDetails,
    navigationDetails: (state) => state.navigationDetails,
}

// export this module.
export default {
    state,
    mutations,
    getters,
    actions
}
