import axios from "axios";

// let BaseUrl = "http://localhost:7300/";
let BaseUrl = process.env.VUE_APP_API_URL + "/";



function getConversation(){
    return axios.get(BaseUrl+'conversation',{
        headers:{
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0cmluZyIsImlkIjoiMSIsInR5cGUiOiJjdXN0b21lciIsImlhdCI6MTY0OTE0MDIyNCwiZXhwIjoxNjU3NzgwMjI0fQ.zIN_aW9oC0Mg_ESXfi8L4r0xT0TYB8EHl_OYutqxUIc'
        }
    })
}

function getMessages(){
    return axios.get(BaseUrl+'message/1',{
        headers:{
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0cmluZyIsImlkIjoiMSIsInR5cGUiOiJjdXN0b21lciIsImlhdCI6MTY0OTE0MDIyNCwiZXhwIjoxNjU3NzgwMjI0fQ.zIN_aW9oC0Mg_ESXfi8L4r0xT0TYB8EHl_OYutqxUIc'
        }
    })
}

function getNewTask(){
    return axios.get(BaseUrl+ 'task/moderator')
}

function getOwnDetail(){
    return axios.get(BaseUrl+'moderator/own-detail');
}

function getDepartment(){
    return axios.get(BaseUrl+ 'moderator/department');
}

function fileUpload(data){
    return axios.post(BaseUrl + 'file/moderator',data);
}


export default {
    getMessages,
    getConversation,
    getNewTask,
    getDepartment,
    getOwnDetail,
    fileUpload

}