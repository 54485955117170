import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/moderator/';



function forgotPassword(data){
    return axios.put(BaseUrl+ 'forgot-password',data);
}

function forgotPasswordChange(data){
    return axios.put(BaseUrl+ 'forgot-password/change',data)
}




export default {
    forgotPassword,
    forgotPasswordChange
   
}